@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "RoxboroughCF";
  src: url("../fonts/Roxborough-CF.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Font-Family/Inter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Font-Family/Inter-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

body,
html {
  width: 100%;
  height: 100%;
  background: white;
  scroll-behavior: smooth;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.home-hero {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;

}

.ready-for {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/* 
  =================================
  BEGIN: Responsive Styles for NAV
  =================================
*/
.dropdown-nav li {
  padding: 0px;
  padding-right: 8px;
}

.no-scroll {
  overflow: hidden;
}

.navBtn {
  justify-content: flex-end;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .dropdown-nav li {
    padding-right: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .dropdown-nav li {
    padding-right: 40px;
  }
}

/* 
  =================================
END: Responsive Styles for NAV
  =================================
*/

/* 
  =================================
  BEGIN: Responsive Styles for HOME
  =================================
*/
@media only screen and (max-width: 768px) {
  /* Your styles for screens below 640px */
  .home-hero {
    flex-direction: column;
    display: flex;
    padding: 10px;
  }

  .home-text {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .home-text h1 {
    font-size: 32px;
    font-weight: 900;
    line-height: 40px;
  }
  .home-text p {
    width: 100%;
    font-size: 14px;
  }

  .hero-star-icon {
    font-size: 24px;
  }

  .home-text button {
    font-size: 14px;
  }

  .hero-bg-mobile {
    border-radius: 20px;
  }

  .home-pink {
    padding: 20px;
    width: 100%;
    flex-direction: column;
  }

  .fund-col {
    width: 100%;
  }
  .fund-col1 {
    width: 100%;
    margin-top: 1rem;
  }
  .home-yellow {
    padding: 20px;
  }
  .home-yellow h4 {
    width: 100%;
    font-size: 18px;
  }
  .home-yellow p {
    width: 100%;
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .home-hero {
    padding: 0 27px;
  }

  .home-text h1 {
    margin-left: 1rem;
  }
  .home-text p {
    margin-left: 1rem;
  }

  .home-text button {
    width: 30%;
    padding: 20px;
    margin-left: 1rem;
  }

  .home-pink {
    padding: 50px;
  }
  .home-yellow {
    padding: 50px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .home-text h1 {
    font-size: 30px;
  }

  .home-text p {
    width: 50%;
    background: rgb(255, 255, 255);
  }
}

/* 
  ===============================
  END: Responsive Styles for HOME
  ===============================
*/

/* 
  =================================
  BEGIN: Responsive Styles for ABOUT
  =================================
*/

@media only screen and (max-width: 768px) {
  .about-drives {
    padding: 20px;
  }

  .drives-content-top {
    flex-direction: column-reverse;
  }

  .visin-cont {
    width: 100%;
    padding-bottom: 1.5rem;
  }

  .driv-imgx {
    width: 100%;
  }

  .drives-content-bottom {
    flex-direction: column;
    gap: 1rem;
  }

  .cores-content {
    width: 100%;
  }

  .we-do-content {
    flex-direction: column;
  }

  .wedo-wrappr {
    width: 90vw;
  }

  .wedo-wrapprx {
    width: 90vw;
    margin-bottom: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .about-drives {
    padding: 50px;
  }
  .drives-content-top {
    flex-direction: column;
    gap: 1rem;
  }

  .driv-imgx {
    width: 100%;
  }

  .visin-cont {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  .our-vission {
    width: 40vw;

    height: 200px;
    margin-top: 20px;
    padding: 70px 20px !important;
  }

  .vission-content {
    width: 60vw;
    height: 200px;
    padding: 50px 20px !important;
  }

  .mission-content {
    width: 60vw;
    height: 200px;
    margin-top: 20px;
    padding: 50px 20px !important;
  }

  .wedo-wrappr {
    width: 90%;
  }

  .wedo-wrapprx {
    width: 90%;
  }

  .we-do-content {
    width: 100% !important;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .about-we-do {
    padding: 30px;
  }
}

/* 
  =================================
  END: Responsive Styles for ABOUT
  =================================
*/

/* 
  =================================
  BEGIN: Responsive Styles for TEAM
  =================================
*/

@media (max-width: 768px) and (min-width: 655px) {
  .content-detail {
    width: 589px;
  }
}

@media only screen and (max-width: 768px) {
  .team-nav {
    margin-top: -2rem;
  }

  .team-content {
    flex-direction: column;
    margin: 0;

    gap: 1rem;
  }

  .content-member {
    width: 100vw;
    padding: 0 30px;
  }

  .fonder-img {
    max-width: 100vw;
  }

  .content-detail {
    position: relative;
  }

  .membership {
    padding: 20px;
    margin-top: -5rem;
    height: 100%;
    padding-top: 5rem;
  }

  .membership-benf {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    height: 50%;
  }

  .membership-top {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
  .membership-top h4 {
    text-align: center;
    font-size: 20px;
  }

  .backg-img {
    visibility: hidden;
  }

  .membership-button {
    position: relative;
    top: -4rem;
    width: 200px;
    margin: 0 auto;
  }

  .membership-right {
    width: 100%;
    align-items: center;
    margin: 0 auto;
    display: flex;
    margin-bottom: -4rem;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .team {
    padding: 30px 0px;
  }

  .membership {
    padding: 50px;
    padding-top: 5rem;
  }

  .membership-benf {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    width: 100%;
    height: 35vh;
  }

  .membership-top {
    position: relative;
    margin: 0 auto;
    width: 40vw;
    height: 30vh;
  }
  .membership-top h4 {
    text-align: center;
    font-size: 20px;
  }

  .backg-img {
    visibility: hidden;
  }

  .membership-button {
    position: relative;
    top: -4rem;
    width: 100%;
    margin: 0 auto;
  }
}

/* 
  =================================
  END: Responsive Styles for TEAM
  =================================
*/

/* 
  =================================
  BEGIN: Responsive Styles for CONTACT
  =================================
*/
@media only screen and (max-width: 768px) {
  .contact {
    padding: 0;
  }
  .contact-nav {
    padding-top: 2rem;
  }

  .contact-card {
    flex-direction: column;
    gap: 1rem;
  }
  .contact-card {
    padding: 20px;
  }

  .can-card {
    padding: 10;
    width: 100%;
  }

  .can-card1 {
    border-radius: 5px;
  }
  .ready-forMD {
    padding: 20px;
  }

  .upcomaing-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .investment-wrapper {
    display: flex;
    flex-direction: column;
  }

  .invest-text {
    margin-bottom: 20rem;
  }

  .join-us {
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }

  .joinnus-arrow {
    display: flex;
    gap: 4rem;
  }

  .member-btn {
    margin-top: 1.3rem;
  }
}

/* 
  =================================
  END: Responsive Styles for CONTACT
  =================================
*/

/* 
  =================================
  BEGIN: Responsive Styles for FOOTER
  =================================
*/

@media only screen and (max-width: 768px) {
  .footer-content {
    padding: 20px;
  }

  .footer-top {
    flex-direction: column;
    align-items: self-start;
    gap: 1rem;
  }

  .quick-links {
    flex-direction: column;
    align-items: flex-start;

    gap: 1rem;
  }

  .footer-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
